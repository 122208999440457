import "./App.css";
import { Routes, Route } from "react-router-dom";

// import AppBar from "./components/AppBar/AppBar";
// import Home from "./components/Home/Home";

function App() {
  return (
    <>
      <div>
        <p className="coming_soon">Coming soon....</p>
      </div>
    </>
  );
}

export default App;
